$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-component-grid {
  > .container > .cmp-container {
    display: grid;
    width: 100%;
    grid-auto-rows: auto;
    gap: (map-get($grid, row-gap)) map-get($grid, column-gap);
  }
}

// COLUMN SIZING VARIANTS

.sw-component-grid--2col {
  > .container > .cmp-container {
    grid-template-columns: 1fr;

    @include media('>=screenSmall') {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.sw-component-grid--3col {
  > .container > .cmp-container {
    grid-template-columns: 1fr;

    @include media('>=screenSmall') {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.sw-component-grid--4col {
  > .container > .cmp-container {
    grid-template-columns: repeat(2, 1fr);

    @include media('>=screenSmall') {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.sw-component-grid__header {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .cmp-title {
    margin-bottom: rem(20);
  }

  @include media('>=screenSmall') {
    margin-bottom: rem(20px);
    flex-wrap: nowrap;

    .cmp-title {
      max-width: rem(470px);
    }
  }
}

.sw-component-grid__headerCopy {
  margin-bottom: rem(20px);

  @include media('>=screenSmall') {
    margin-bottom: 0;
    min-width: 50%;
  }
}

.sw-component-grid__headerLink {
  white-space: nowrap;
  a::after {
    content: '';
    display: inline-block;

    @include right-nudge;
    @include icon-carat-right;
  }

  @include media('>=screenSmall') {
    padding-left: rem(40);
  }
}

// Customize your grids here

.componentgrid {
  // Only for image grid component
  &.cmp-image-grid {
    padding: rem(50) 0;

    .container > .cmp-container {
      > * {
        max-width: 7.5em;
        margin: auto;
      }
    }

    .sw-component-grid {
      > .container > .cmp-container {
        padding-top: rem(16);
      }
    }

    @include media('>=desktop') {
      .sw-component-grid {
        > .container > .cmp-container {
          gap: (map-get($grid, row-gap)) (2 * map-get($grid, column-gap));
        }
      }

      .container > .cmp-container {
        > * {
          max-width: 15em;
        }
      }
    }

  }

  // Only for value cards grid component
  &.cmp-cards-grid {
    @include sw-container(false, 1050);

    > .sw-component-grid > .container .cmp-container {
      align-items: flex-start;
    }
  }

  &.cmp-leadership-grid {
    @include sw-container(false, 1045);
  }
}
